<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" icon="el-icon-search">
			<div slot="header-right">
				<el-button plain size="mini" @click="list()">查询结果</el-button>
			</div>
			<el-form slot="body" inline ref="form" :model="searchParm" label-width="120px">
				<el-row>
					<el-col :span="6">
						<el-form-item label="接收企业：" >
							<el-input v-model="searchParm.entName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="上传时间开始：">
							<el-date-picker v-model="searchParm.timeStart" value-format="yyyy-MM-dd HH:mm:ss"
								type="datetime" placeholder="请选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="上传时间结束：">
							<el-date-picker v-model="searchParm.timeEnd" value-format="yyyy-MM-dd HH:mm:ss"
								type="datetime" placeholder="请选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</sm-card>
		<sm-card title="数据列表" class="table" icon="el-icon-s-operation">
			<div slot="body">
				<el-table ref="usertable" :data="tableData.records" border v-if="tableData.records.length>0"
					:header-cell-style="{'background-color':'#f9fafc'}" style="margin-bottom: 20px;">
					<el-table-column prop="title" label="文档名称">
					</el-table-column>
					<el-table-column prop="entName" label="接收企业">
					</el-table-column>
					<el-table-column prop="create_time" label="上传时间">
					</el-table-column>
					<el-table-column prop="remarks" label="备注">
					</el-table-column>
				</el-table>
				<div class="pagination" v-if="tableData.records.length>0">
					<el-pagination style="float: right;margin-bottom: 10px;" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" :current-page.sync="searchParm.current"
						:page-sizes="[5, 10, 20, 50]" :page-size="searchParm.size"
						layout="total, sizes, prev, pager, next, jumper" :total="tableData.total">
					</el-pagination>
				</div>
				<div class="noneData" v-if="tableData.records.length==0">
					<el-empty :image="require('@assets/osImg/noneData.png')"></el-empty>
				</div>
			</div>
		</sm-card>
		<sm-footer></sm-footer>

	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import smFooter from "@c/smFooter.vue"
	export default {
		components: {
			smCard,
			smFooter,
		},
		data() {
			return {
				tableData: {
					records: [],
				},
				searchParm: {
					size: 10,
					current: 1
				},
				checkedAll: false,
			}
		},
		methods: {
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			handleSizeChange(val) {
				this.searchParm.size = val;
				this.list();
			},
			//展示数据
			list() {
				this.$get("/shop-api/report/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
		},
		mounted() {
			this.list();
		}
	};
</script>
<style lang='scss' scoped>
	.table-header .el-select {
		width: 100px;
		margin-right: 10px;
	}

	.el-form-item {
		display: flex !important;
	}

	.el-form-item .el-form-item__content {
		flex: 1;
	}

	.el-input {
		width: 100% !important;
	}

	.pagination {
		position: relative;
		
		>* {
			margin-right: 10px !important;
		}
	}
	.noneData{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
</style>
